import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import Spin from "react-reveal/Spin";
const Industries = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Industries - Crocent International";
  }, []);
  return (
    <>
      <Header />
      <div className="hero-sec2 ">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Industries</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">Industries We Work</h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <main>
        <section className="industries_sec">
          <div className="container">
            <Fade left>
              <div className="industries_contain ">
                <h2 className="sub_heading2 margin____auto">
                  <span className="text_yellow">industries </span> WE WORK
                </h2>
                <p className="industries_hedding">
                  Revolutionising the way you interact with technology, our
                  innovative apps bring a new level of convenience, <br /> efficiency,
                  and enjoyment to your digital experiences.
                </p>
              </div>
            </Fade>

            <div className="conatain_sec_ind">
              <div className="row_ind">
                <div className="card1">
                  <Fade left>
                    <div className="contain_design_ind">
                      <span>
                        <span className="treeeLine"></span>Web Services{" "}
                      </span>
                      <h4>
                        Giving big ideas a strong foundation from the grassroots
                      </h4>
                      <p>
                        Crocent International provides a digital storefront for
                        customers to showcase their brand, products and services
                        online. Our professional web services include custom
                        design, development, integration with back-end systems,
                        testing, and maintenance. We understand that every
                        business has unique needs, which is why we offer custom
                        web and also app development services that are tailored
                        to your specific goals and requirements. We can help
                        companies stay ahead of the curve in today's
                        ever-evolving digital landscape with a modern and
                        convenient online experience. 
                      </p>
                      <button className="btn">Read More</button>
                    </div>
                  </Fade>
                </div>
                <div className="card2">
                  <Fade right>
                    <div className="image_design_ind">
                      <img src="assets/market/market2.png" alt="" />
                      <div className="backround_ind"></div>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="row_ind">
                <div className="card2">
                  <Fade left>
                    <div className="image_design_ind">
                      <img src="assets/market/market1.png" alt="" />
                      <div className="backround_ind2"></div>
                    </div>
                  </Fade>
                </div>
                <div className="card1">
                  <Fade right>
                    <div className="contain_design_ind">
                      <span>
                        <span className="treeeLine"></span>ecommerce{" "}
                      </span>
                      <h4>
                        Redefining shopping experience with a spark of
                        innovation & technology
                      </h4>
                      <p>
                        Our e-commerce services are designed to help you
                        establish and grow a successful online store through
                        platform selection, customization, payment gateway
                        integration, and shipping and fulfilment. We will help
                        you select the right e-commerce platform that suits your
                        business needs, customise it to your branding, and
                        integrate payment gateways like PayPal, Stripe, or
                        Authorize.net for secure online transactions.  
                      </p>
                      <button className="btn">Read More</button>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="row_ind">
                <div className="card1">
                  <Fade left>
                    <div className="contain_design_ind">
                      <span>
                        <span className="treeeLine"></span>entertainment{" "}
                      </span>
                      <h4>Accelerating the new era of media & publishing</h4>
                      <p>
                        Our media and entertainment services are designed to
                        help you produce and promote high-quality content that
                        engages your audience and enhances your brand. We use
                        the latest technology and equipment to ensure that
                        content is top-notch, with excellent sound quality,
                        stunning visuals, and captivating storytelling. 
                      </p>
                      <button className="btn">Read More</button>
                    </div>
                  </Fade>
                </div>
                <div className="card2">
                  <Fade right>
                    <div className="image_design_ind">
                      <img src="assets/market/market4.png" alt="" />
                      <div className="backround_ind"></div>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="row_ind">
                <div className="card2">
                  <Fade left>
                    <div className="image_design_ind">
                      <img src="assets/market/market3.png" alt="" />
                      <div className="backround_ind2"></div>
                    </div>
                  </Fade>
                </div>

                <div className="card1">
                  <Fade right>
                    <div className="contain_design_ind">
                      <span>
                        <span className="treeeLine"></span>Hosting{" "}
                      </span>
                      <h4>Web Hosting Made Easy & Affordable</h4>
                      <p>
                        Our hosting solutions are powered by top-of-the-line
                        servers and supported by our team of experienced
                        technicians. We use the latest technology to ensure that
                        your website is always up and running smoothly, with
                        fast loading times and minimal downtime. Our services
                        are designed to provide you with reliable, secure, and
                        easy-to-use hosting solutions that help your website
                        thrive. 
                      </p>
                      <button className="btn">Read More</button>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="busness_section">
          <div className="container">
            <div className="download_app">
              <div className="heading">
                <h4 className="mainh4">
                  <span>Our</span> Applications
                </h4>
                <p>
                Revolutionising the way you interact with technology, our innovative apps bring a new level of convenience, efficiency, and enjoyment to your digital experiences.

                </p>
              </div>

              <div className="sectionimage">
                <div className="row">
                  <div className="col-md-6 cst_order2">
                    <Fade left>
                      <div className="contain_all_dwonloadapp">
                        <h5 className="h5">
                          <span>GET OUR APP </span> <br />
                          CROCENT ASTRO
                        </h5>
                        <p>
                          Crocent Astro app designed for astrology enthusiasts.
                          It offers a range of features that allow to explore
                          your astrological birth chart, including sun sign,
                          moon sign, rising sign, and planetary placements. Also
                          learn about daily horoscopes and personalized readings
                          based on the user's birth chart. 
                        </p>
                        <div className="app_store">
                          <div className="d">
                            <img src="assets/phone/play.png" alt="" />
                            <img src="assets/phone/apple.png" alt="" />
                          </div>

                          <img
                            className="download_qr"
                            src="assets/phone/demo_qr.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </Fade>
                  </div>

                  <div className="col-md-6 cst_order1">
                    <Fade right>
                      <div className="image_container">
                        <div className="phone_sec">
                          <img
                            src="assets/phone/pn.png"
                            alt=""
                            className="phone"
                          />
                          <Spin>
                            <img
                              src="assets/phone/cercle12.png"
                              alt=""
                              className="phone_bg"
                            />
                          </Spin>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>

              <div className="sectionimage">
                <div className="row">
                  <div className="col-md-6">
                    <div className="image_container">
                      <div className="phone_sec">
                        <img
                          src="assets/phone/helo_pn.png"
                          alt=""
                          className="phone"
                        />
                        <Spin>
                          <img
                            src="assets/phone/helo.png"
                            alt=""
                            className="phone_bg"
                          />
                        </Spin>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 nargin__60">
                    <div className="contain_all_dwonloadapp">
                      <h5 className="h5">
                        <span>GET OUR APP </span> <br />
                        HELLO TECH
                      </h5>
                      <p>
                        Hello Tech provides solutions to technical problems
                        related to software, apps, and mobile devices. Designed
                        to help users troubleshoot and resolve issues they may
                        encounter while using their digital devices. It offers a
                        user-friendly interface and provides step-by-step
                        instructions to help users diagnose and solve technical
                        problems. With HelloTech, users can enjoy a seamless and
                        hassle-free digital experience.
                      </p>
                      <div className="app_store">
                        <div className="d">
                          <img src="assets/phone/play.png" alt="" />
                          <img src="assets/phone/apple.png" alt="" />
                        </div>

                        <img
                          className="download_qr"
                          src="assets/phone/demo_qr.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sectionimage ">
                <div className="row">
                  <div className="col-md-6 cst_order2">
                    <Fade left>
                      <div className="contain_all_dwonloadapp">
                        <h5 className="h5">
                          <span>GET OUR APP </span> <br />
                          GYMELLA
                        </h5>
                        <p>
                          Gymella is a fitness app designed to help users
                          achieve their fitness goals by providing personalised
                          workout plans and tracking tools anywhere and
                          everywhere. The app offers a wide variety of workout
                          routines, including cardio, and yoga, which can be
                          customised based on the user's fitness level and
                          goals. Download a comprehensive fitness app to stay
                          motivated and achieve your fitness goals.
                        </p>
                        <div className="app_store">
                          <div className="d">
                            <img src="assets/phone/play.png" alt="" />
                            <img src="assets/phone/apple.png" alt="" />
                          </div>

                          <img
                            className="download_qr"
                            src="assets/phone/demo_qr.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </Fade>
                  </div>

                  <div className="col-md-6 cst_order1">
                    <Fade right>
                      <div className="image_container ">
                        <div className="phone_sec">
                          <img
                            src="assets/phone/gymilla_pn.png"
                            alt=""
                            className="phone"
                          />{" "}
                          <Spin>
                            <img
                              src="assets/phone/gymilla.png"
                              alt=""
                              className="phone_bg"
                            />
                          </Spin>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Industries;
