import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Shimmer } from "react-shimmer";
const Blogs = () => {
  const [data, setData] = useState(null);
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Blogs - Crocent International";
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.crocentinternational.com/blog/BlogWeb/crocentinternational.com"
        );
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Blogs</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">Latest News & Articles</h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="article mt_100 container">
          <Fade top>
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">latest</span> articles
              </h2>
              <p>
                Get the latest tips and tricks in technology and marketing to
                learn the best <br /> practices you can put into action. 
              </p>
            </div>
          </Fade>

          {data ? (
            <div className="row">
              {data.map((item, index) => (
                <Link to={`blog/${item.url}`} className="col-md-6">
                  <Fade top>
                    <div className="article-blog2 ">
                      <LazyLoadImage
                        src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                        width={"100%"}
                        height={"100%"}
                        alt="Image Alt"
                        effect="blur"
                      />

                      <div className="article_footer shadow-sm ">
                        <h5 className="date">{item.post_date}</h5>
                        <p className="headline">{item.blog_title}</p>
                        <Link to={`blog/${item.url}`} className="read_more">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </Fade>
                </Link>
              ))}
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <Fade top>
                  <div className="article-blog2">
                    <div className="shimmer-placeholder">
                      <Shimmer width={"100%"} height={400} />
                    </div>
                    <div className="article_footer shadow-sm">
                      <Shimmer width={"50%"} height={16} />
                      <br />
                      <Shimmer width={"100%"} height={16} />
                      <br />
                      <Shimmer width={"100%"} height={16} />
                      <br />
                      <a href="" className="read_more">
                        <Shimmer width={"20%"} height={16} />
                      </a>
                      <br />
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-6">
                <Fade top>
                  <div className="article-blog2">
                    <div className="shimmer-placeholder">
                      <Shimmer width={"100%"} height={400} />
                    </div>
                    <div className="article_footer shadow-sm">
                      <Shimmer width={"50%"} height={16} />
                      <br />
                      <Shimmer width={"100%"} height={16} />
                      <br />
                      <Shimmer width={"100%"} height={16} />
                      <br />
                      <a href="" className="read_more">
                        <Shimmer width={"20%"} height={16} />
                      </a>
                      <br />
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          )}

          {/* {data ? (
              // Render your data here
              data.forEach((item, index) => (
                <div className="col-md-6">
                  <Fade top>
                    <div className="article-blog2 ">
                      <img src="assets/article1.png" alt="" />
                      <div className="article_footer shadow-sm ">
                        <h5 className="date">August 12,2022 - John Doe</h5>
                        <p className="headline">
                          Lorem Ipsum is simply dummy text of the printing.
                        </p>
                        <a href="" className="read_more">
                          Read More
                        </a>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))
            ) : (
              // Render a loading indicator or placeholder while data is being fetched
              <p>Loading...</p>
            )} */}

          <Fade top>
            <div className="allviewblog">
              {/* <button className="btn btn-outline-custom market-btn">
                Read More
              </button> */}
            </div>
          </Fade>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Blogs;
