import React, { useEffect } from "react";
import { BsPerson, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import Drawal from "./Drawal";
import Roll from "react-reveal/Roll";
import LightSpeed from "react-reveal/LightSpeed";
import Fade from "react-reveal/Fade";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import toast, { Toaster } from "react-hot-toast";

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const navRef = React.useRef(null);
  const handleClick = () => {
    setOpen(true);
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/crocent-story" || location.pathname ==="/astro-app" || location.pathname ==="/gymella" || location.pathname ==="/hello-tech") {
      navRef.current.classList.add("new_nav");
      console.log(location.pathname);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <header className="header" ref={navRef} id="header">
      <Toaster />
      <nav className="navbar container">
        <Fade left>
          <Link to="/" className="brand">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
              alt="banner"
            />
          </Link>
        </Fade>
        <Drawal />
        <div className="menu" id="menu">
          <ul className="menu-inner margin__zeo_left">
            <Fade top>
              <li className="menu-item">
                <Link to="/crocent-story" className="menu-link">
                  The Crocent Story
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/about" className="menu-link">
                  About
                </Link>
              </li>
              <li className="menu-item dropdown_react_bs">
                <Link to="#" className="menu-link">
                  Our Businesses
                  <div className="dropdown_manu">
                    <div className="row">
                      <div className="col-4">
                        <div className="over_view">
                          <Link to="industries">Overview</Link>
                        </div>
                      </div>

                      <div className="col-4 border_manu">
                        <div className="industries_list">
                          <ul>
                            <li>
                              <Link className="dropdownheading" to="#">
                                industries
                              </Link>
                            </li>
                            <li>Web Services</li>
                            <li>Hosting</li>
                            <li>ecommerce</li>
                            <li>entertainment</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-4 border_manu">
                        <div className="industries_list">
                          <ul>
                            <li>
                              <Link className="dropdownheading" to="#">
                                Applications
                              </Link>
                            </li>
                            <li><Link className="color_defaul_dark" to="/gymella">Gymella</Link></li>
                            <li><Link className="color_defaul_dark" to="/hello-tech">Hello Tech</Link></li>
                            <li><Link className="color_defaul_dark" to="/astro-app">Crocent Astro</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>

              <li className="menu-item dropdown_react_bs">
                <Link to="#" className="menu-link">
                  Careers
                  <div className="dropdown_manu2">
                    <div className="row">
                      <div className="col-6">
                        <div className="over_view2">
                          <Link to="/working-crocent-international">
                            Working at Crocent International
                          </Link>
                          <div className="border_right_react"></div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="over_view2">
                          <Link to="/apply-crocent-international">
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/blogs" className="menu-link">
                  Blogs
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact-us" className="menu-link">
                  Contact Us
                </Link>
              </li>
            </Fade>
            <Fade right>
              <li className="menu-item">
                <span className="border_diveider">|</span>{" "}
                <a
                  href="http://pms.crocentinternational.com/index.php/signin"
                  target="_blank"
                >
                  <BsPerson className="login" />
                </a>
                {/* <a href="#" className="search_box" onClick={handleClick}>
                  <BsSearch className="Search" />
             
                </a> */}
              </li>
            </Fade>
          </ul>
        </div>
      </nav>

      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="We are hardworking for this function!"
        action={action}
      />
    </header>
  );
};

export default Header;
