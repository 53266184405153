import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import Roll from "react-reveal/Roll";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import { LightSpeed } from "react-reveal";
import Gettouch from "../common/Gettouch";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Hidden } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ApplyCrocentWebServices = () => {
  // React.useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const notifyerror = () => toast.error("Application sent Failed!");
  const notify = () => toast.success("Application sent successfully!");
  const notifyemapty = () => toast.error("Please fill out all fields!");
  const [positionData, setPositionData] = useState(null);
  const [fastname, setFastName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [position, setPosition] = useState("");
  const [interview, setInterview] = useState("");
  const [employment, setEmployment] = useState("");
  const [resume, setResume] = useState("");
  const [attachment, setAttachment] = useState("");

  const [status, setStatus] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (
      fastname == "" ||
      lastname == "" ||
      email == "" ||
      number == "" ||
      position == "" ||
      interview == "" ||
      employment == "" ||
      resume == ""
    ) {
      notifyemapty()
    }else{

   

    let data = new FormData();

    data.append("name", fastname + " " + lastname);
    data.append("email", email);
    data.append("phone", number);
    data.append("what_position", position);
    data.append("available_start_date", interview);
    data.append("current_employment_status", employment);
    data.append("resume_url", resume);


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://pms.crocentinternational.com/api/apply.php",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data == true) {
          setStatus(true);
          notify();
          setPositionData(null);
          setFastName("");
          setLastName("");
          setEmail("");
          setNumber("");
          setPosition("");
          setInterview("");
          setEmployment("");
          setResume("");
          setAttachment("");
        } else {
          notifyerror();
        }
        //console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        notifyerror();
      });
    }
  };

  const loadPosition = () => {
    let config = {
      method: "get",

      url: "https://pms.crocentinternational.com/api/get_possition.php",
    };

    axios
      .request(config)
      .then((response) => {
        setPositionData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    document.title = "Apply At Crocent International - Crocent International";
    loadPosition();
  }, []);
  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Apply At Crocent Web Services</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">
                      Opportunity to change the world
                    </h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section apply_grid="true">
          <div single_grid="true" className="container">
            {/* <div className="team_hedding ">
              <h2>
                <span className="text_yellow">Apply </span> NOW
              </h2>
              <p>
                We believe that everyone has unique aspirations and ambitions,
                and we are <br /> dedicated to helping them achieve their goals.
              </p>
            </div> */}

            <form className="appy_now_form" onSubmit={submitForm}>
              <div className="form_logo">
                <div class="team_hedding ">
                  <h2>
                    <span class="text_yellow">Apply </span> NOW
                  </h2>
                  <p>
                    We believe that everyone has unique aspirations and
                    ambitions, and we are <br /> dedicated to helping them
                    achieve their goals.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    Fast <span>Name</span>
                  </label>

                  <input
                    type="text"
                    value={fastname}
                    onChange={(e) => setFastName(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Fast Name"
                  />
                </div>
                <div className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    Last <span>Name</span>
                  </label>

                  <input
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="row mtrs-2">
                <div className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    Email <span>Address</span>
                  </label>

                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Email Address"
                  />
                </div>

                <div className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    <span></span> Phone <span>Number</span>
                  </label>

                  <input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <div className="row mtrs-2">
                <div className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    <span>Position </span> are you
                    <span> applying for?</span>
                  </label>
                  <select
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    className="get_in_from_cont wf_full"
                  >
                    <option value="">Select Position</option>
                    {positionData == null ? (
                      <option value="">Item Not Found</option>
                    ) : (
                      positionData.map((items) => {
                        return <option value={items.id}>{items.name}</option>;
                      })
                    )}
                  </select>
                </div>
                <div cl className="col-md-6">
                  <label className="title_hedding_input" htmlFor="">
                    Available <span>For Interview</span>
                  </label>

                  <input
                    type="date"
                    value={interview}
                    onChange={(e) => setInterview(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Available For Interview"
                  />
                </div>
              </div>

              <div className="row mtrs-2">
                <div class="col-md-12">
                  <label
                    for="current_employment_status"
                    class="col-md-12 title_hedding_input"
                  >
                    What is your <span> current employment</span> status?
                  </label>
                  <div class="col-md-12">
                    <input
                      onChange={(e) => setEmployment(e.target.value)}
                      type="radio"
                      name="current_employment_status"
                      checked="checked"
                      id="employed"
                      class="form-check-input account-type"
                      data-msg-required="This field is required."
                    />
                    <label
                      for="type_organization"
                      class="type_organization mr15"
                    >
                      Employed
                    </label>

                    <input
                      type="radio"
                      name="current_employment_status"
                      value="Self-Employed"
                      id="self_employed"
                      onChange={(e) => setEmployment(e.target.value)}
                      class="form-check-input account-type"
                      data-msg-required="This field is required."
                    />
                    <label for="type_person" class="type_organization">
                      Self-Employed
                    </label>
                    <input
                      type="radio"
                      name="current_employment_status"
                      value="Unemployed"
                      onChange={(e) => setEmployment(e.target.value)}
                      id="unemployed"
                      class="form-check-input account-type"
                      data-msg-required="This field is required."
                    />
                    <label for="type_person" class="type_organization">
                      Unemployed
                    </label>
                    <input
                      type="radio"
                      name="current_employment_status"
                      value="Student"
                      id="student"
                      onChange={(e) => setEmployment(e.target.value)}
                      class="form-check-input account-type"
                      data-msg-required="This field is required."
                    />
                    <label for="type_person" class="type_organization">
                      Student
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mtrs-2">
                <div className="col-md-12">
                  <label className="title_hedding_input " htmlFor="">
                    Resume <span>URL</span>
                  </label>

                  <input
                    type="url"
                    value={resume}
                    onChange={(e) => setResume(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="URL"
                  />
                </div>
                <div className="col-md-12 mtrs-2">
                  <label className="title_hedding_input " htmlFor="">
                    Add <span>Attachment</span>
                  </label>

                  <input
                    type="file"
                    value={attachment}
                    onChange={(e) => setAttachment(e.target.value)}
                    className="get_in_from_cont wf_full"
                    placeholder="Add Attachment"
                  />

                  {status == true ? (
                    <p className="text-success_msg">
                      Thanks you very much for reaching out to us, We will get
                      back to you as soon as possible!
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mt-5">
                <button type="submit" className="get_in_btn wf_full">
                  Apply Now
                </button>
              </div>
            </form>
          </div>
        </section>

        <section className="get_in_touch_ci">
          <div className="bg_get_in_touch">
            <div></div>
          </div>
          <div className="row container">
            <div className="col-md-5">
              <Roll left>
                <div className="get_in_touch_images">
                  <img className="" src="assets/frame.png" alt="" />
                </div>
              </Roll>
            </div>

            <div className="col-md-7">
              <div className="get_in_from">
                <LightSpeed right>
                  <div className="touch_contain">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">Apply</span> Now
                    </h2>
                    <p className="about-us-hedding">
                      We believe that everyone has unique aspirations and
                      ambitions, and we are dedicated to helping them achieve
                      their goals.
                    </p>
                  </div>
                </LightSpeed>

                <LightSpeed right>
                  <Gettouch />
                </LightSpeed>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ApplyCrocentWebServices;
