import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
const PrivacyPolicy = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Privacy Policy - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Privacy Policy</h1>
              </Fade>
              
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="mb_100 mt_100 container terms">
          <Fade bottom>
            <p>
              We value your privacy very highly. Please read this Privacy Policy
              carefully before using the{" "}
              <a target="_blank" href="http://crocentinternational.com/">
                http://crocentinternational.com/
              </a>{" "}
              as this Privacy Policy contains important information regarding
              your privacy and how we may use the information we collect about
              you.
            </p>
          </Fade>
          <Fade bottom>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us. This Privacy
              Policy applies only to our online activities and is valid for
              visitors to our website with regards to the information that they
              shared and/or collected in Crocent International. This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </p>
          </Fade>
          <Fade bottom>
            <h4>Consent</h4>
          </Fade>
          <Fade bottom>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
          </Fade>
          <Fade bottom>
            <h4>Information we collect</h4>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.When you register
              for an Account, we may ask for your contact information, including
              items such as name, company name, address, email address, and
              telephone number.
            </p>
          </Fade>

          <h4>1. How We Use Your Information</h4>
          <ol>
            <li>
              <p>
                We may use your personal information for the following purposes:
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <p>Provide, operate, and maintain our website </p>
                </li>
                <li>
                  <p>Improve, personalise, and expand our website </p>
                </li>
                <li>
                  <p>Understand and analyse how you use our website </p>
                </li>
                <li>
                  <p>
                    Develop new products, services, features, and functionality{" "}
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes{" "}
                  </p>
                </li>
                <li>
                  <p>
                    To comply with legal obligations or protect our rights and
                    interests{" "}
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p></p>
              <p>
                We may also share your personal information with third-party
                service providers that help us operate our business or provide
                services on our behalf. These providers are contractually bound
                to protect your information and only use it for the purposes we
                specify.
              </p>
            </li>
          </ol>

          <h4>2. Your Rights and Choices</h4>
          <ol>
            <li>
              <p>
                You have certain rights and choices regarding your personal
                information, including the right to:
              </p>
            </li>
            <li>
              <ul>
                <li>
                  <p> Access and receive a copy of your information </p>
                </li>
                <li>
                  <p>Correct or update your information </p>
                </li>
                <li>
                  <p>
                    Object to or restrict the processing of your information{" "}
                  </p>
                </li>
                <li>
                  <p>Delete your information </p>
                </li>
                <li>
                  <p>Opt-out of marketing communications </p>
                </li>
                <li>
                  <p>
                    To exercise these rights or learn more about your choices,
                    please contact us at{" "}
                    <a href="mailto:work@crocentinternational.com">
                      work@crocentinternational.com
                    </a>{" "}
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p></p>
            </li>
          </ol>

          <h4>4. Data Security</h4>
          <ol>
            <li>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use, and disclosure. However, no
                method of transmission over the Internet or electronic storage
                is completely secure, and we cannot guarantee absolute security.
              </p>
            </li>
          </ol>
          <h4>5. Changes to this Privacy Policy</h4>
          <ol>
            <li>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our data practices or legal requirements. We
                encourage you to review this policy periodically for any
                updates.
              </p>
            </li>
          </ol>
          <h4>6. Contact Us</h4>
          <ol>
            <li>
              <p>
                If you have any questions or concerns about our privacy
                practices or this policy, please contact us at
                <a href="mailto:work@crocentinternational.com">
                  work@crocentinternational.com
                </a>
              </p>
            </li>
          </ol>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
