
import './App.css';
import React from 'react'
import {BrowserRouter as Router, Route } from "react-router-dom"
import About from './components/pages/About'
import Home from './components/pages/Home'
import Blogs from './components/pages/Blogs';
import Contact from './components/pages/Contact';
import Testimonials from './components/pages/Testimonials';
import AllBlogs from './components/pages/AllBlogs';
import Industries from './components/pages/Industries';
import Team from './components/pages/Team';
import CrocentStory from './components/pages/CrocentStory';
import ApplyCrocentInternational from './components/pages/ApplyCrocentInternational';
import ApplyCrocentWebServices from './components/pages/ApplyCrocentWebServices';
import WorkingCrocentInternational from './components/pages/WorkingCrocentInternational';
import AstroApp from './components/pages/AstroApp';
import HeloTech from './components/pages/HeloTech';
import Gymella from './components/pages/Gymella';
import TermsConditions from './components/pages/TermsConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
const App = () => {
  return (
    <>
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/testimonials" component={Testimonials} />
        <Route exact path="/blog/:url" component={AllBlogs} />
        <Route exact path="/industries" component={Industries} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/astro-app" component={AstroApp} />
        <Route exact path="/hello-tech" component={HeloTech} />
        <Route exact path="/gymella" component={Gymella} />
        <Route exact path="/terms-and-conditions" component={TermsConditions} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/crocent-story" component={CrocentStory} />
        <Route exact path="/apply-crocent-international" component={ApplyCrocentInternational} />
        <Route exact path="/apply-crocent-web-services" component={ApplyCrocentWebServices} />
        <Route exact path="/working-crocent-international" component={WorkingCrocentInternational} />
        {/* <Route path="*" component={Notfound} /> */}
      </Router>


    </>
  )
}

export default App