import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import "./app_min.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import Spin from "react-reveal/Spin";
const HeloTech = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = " Hello Tech - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="hero-app banner banner-section">
          <div className="sectionimage_app ">
            <div className="row container">
              <div className="col-md-6 cst_order2">
                <Fade left>
                  <div className="contain_all_dwonloadapp_app">
                    <h5 className="h5">
                      <span>GET OUR APP </span> <br />
                      HELLO TECH
                    </h5>
                    <p>
                      Hello Tech provides solutions to technical problems
                      related to software, apps, and mobile devices. Designed to
                      help users troubleshoot and resolve issues they may
                      encounter while using their digital devices. It offers a
                      user-friendly interface and provides step-by-step
                      instructions to help users diagnose and solve technical
                      problems. With HelloTech, users can enjoy a seamless and
                      hassle-free digital experience.
                    </p>
                    <div className="app_store">
                      <div className="d">
                        <img src="assets/phone/play.png" alt="" />
                        <img src="assets/phone/apple.png" alt="" />
                      </div>

                      <img
                        className="download_qr"
                        src="assets/phone/demo_qr.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Fade>
              </div>

              <div className="col-md-6 cst_order1">
                <Fade right>
                  <div className="image_container_app">
                    <div className="phone_sec">
                      <img
                        src="assets/phone/helo_pn.png"
                        alt=""
                        className="phone"
                      />
                      <Spin>
                        <img
                          src="assets/phone/helo.png"
                          alt=""
                          className="phone_bg"
                        />
                      </Spin>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="section_option_apps">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>01</span>
                    </div>
                    <div className="container_card">
                      <h4>Device Troubleshooting</h4>
                      <p>
                        Comprehensive troubleshooting assistance for various
                        devices, including computers, smartphones, tablets,
                        smart home devices, and more.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>02</span>
                    </div>
                    <div className="container_card">
                      <h4>On-Demand Tech Support</h4>
                      <p>
                        Connecting users with skilled technicians who can
                        remotely diagnose and fix technical problems, saving
                        time and hassle.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>03</span>
                    </div>
                    <div className="container_card">
                      <h4>Device Setup & Installation</h4>
                      <p>
                        Guidance and assistance for setting up new devices, such
                        as computers, printers, routers, smart home devices, and
                        more.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default HeloTech;
