import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import "./app_min.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import Spin from "react-reveal/Spin";
const AstroApp = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Astro App - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="hero-app banner banner-section">
          <div className="sectionimage_app ">
            <div className="row container">
              <div className="col-md-6 cst_order2">
                <Fade left>
                  <div className="contain_all_dwonloadapp_app">
                    <h5 className="h5">
                      <span>GET OUR APP </span> <br />
                      CROCENT ASTRO
                    </h5>
                    <p>
                      Crocent Astro app designed for astrology enthusiasts. It
                      offers a range of features that allow to explore your
                      astrological birth chart, including sun sign, moon sign,
                      rising sign, and planetary placements. Also learn about
                      daily horoscopes and personalized readings based on the
                      user's birth chart. 
                    </p>
                    <div className="app_store">
                      <div className="d">
                        <img src="assets/phone/play.png" alt="" />
                        <img src="assets/phone/apple.png" alt="" />
                      </div>

                      <img
                        className="download_qr"
                        src="assets/phone/demo_qr.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Fade>
              </div>

              <div className="col-md-6 cst_order1">
                <Fade right>
                  <div className="image_container_app">
                    <div className="phone_sec">
                      <img src="assets/phone/pn.png" alt="" className="phone" />
                      <Spin>
                        <img
                          src="assets/phone/cercle12.png"
                          alt=""
                          className="phone_bg"
                        />
                      </Spin>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="section_option_apps">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>01</span>
                    </div>
                    <div className="container_card">
                      <h4>Personalised Horoscopes</h4>
                      <p>
                        Daily, weekly, and monthly horoscopes tailored to the
                        user's zodiac sign, offering insights and predictions
                        about various aspects of their life.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>02</span>
                    </div>
                    <div className="container_card">
                      <h4>Birth Chart Analysis</h4>
                      <p>
                        Comprehensive analysis of the user's astrological
                        profile, including their sun sign, moon sign, rising
                        sign, and planetary placements.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>03</span>
                    </div>
                    <div className="container_card">
                      <h4>Astrology Articles & Insights</h4>
                      <p>
                        Articles, blogs covering topics like the meaning of
                        zodiac signs, astrological compatibility, planetary
                        influences and astrological techniques.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AstroApp;
