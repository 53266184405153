import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import axios from "axios";
import Fade from "react-reveal/Fade";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Shimmer from "react-shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const AllBlogs = () => {
  
  const [data, setData] = useState(null);
  const [recent, setRecent] = useState(null);
  const { url } = useParams();
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const setRecents = async () => {
    try {
      const response = await axios.get("https://admin.crocentinternational.com/blog/recent_blog/crocentinternational.com");
      setRecent(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.crocentinternational.com/blog/BlogWebUrl/" + url);
        setData(response.data);
        console.log(response.data);
        document.title = response.data.meta_desc;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    setRecents();
    fetchData();
  }, [url]);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <h1 className="heading-xl"> </h1>
              <div className="row-custom">
                <div className="col-custom">
                  <h2 className="heading-lg"> Latest News & Articles</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="main_body">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                {data ? (
                  <div className="blog_body">
                    <div className="blog_head">
                      <p>
                        {data.post_date}{" "}
                        <span className="border_diveider">|</span>{" "}
                        <span>
                          <BsEye className="BsEye" />
                          {data.views}
                        </span>
                      </p>
                      <h2>{data.blog_title}</h2>
                    </div>
                    <div className="images">
                      <Shimmer>
                        <LazyLoadImage
                          src={`https://admin.crocentinternational.com/assets/upload/images/${data.blog_image}`}
                          alt="Image Alt"
                          width={"100%"}
                          effect="blur"
                        />
                      </Shimmer>
                    </div>
                    <div
                      className="containt"
                      dangerouslySetInnerHTML={{ __html: data.desc }}
                    ></div>
                  </div>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
              <div className="col-md-4">
                <div className="blog_side_bar">
                  {recent ? (
                    <div className="blog_recent_bar">
                      <div className="recent_head">
                        <h4>
                          Recent<span> Blog</span>
                        </h4>
                      </div>
                      {recent.map((item, index) => (
                        <Link to={`${item.url}`} className="recent_item">
                          <div className="img">
                            <Shimmer>
                              <LazyLoadImage
                                src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                                alt="Image Alt"
                                width={"100%"}
                                effect="blur"
                              />
                            </Shimmer>
                          </div>
                          <div className="recent_hedding">
                            <h4>{item.blog_title}</h4>
                            <p>Read More</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}

                  <div className="hire_us_blog">
                    <h4>
                      <span>Hire Us</span> <br />
                      For Next Project{" "}
                    </h4>
                    <Link to="/contact-us">Contact Us Now</Link>
                    <div className="overlay_blog"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AllBlogs;
