import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import "./crocent_story.css";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import SideNavigation from "./SideNavigation";
import ReactFullpage from "@fullpage/react-fullpage";
import { Padding } from "@mui/icons-material";
import { Fade } from "react-reveal";

const CrocentStory = () => {
  const [activeDot , seActiveDot]=useState(0);
  useEffect(() => {
    // document.body.style.overflow = 'hidden';

    // // Clean up the event listener on component unmount
    // return () => {
    //   document.body.style.overflow = 'auto';
    // }; 
    document.title = "The Crocent Story - Crocent International";
  }, []);
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSlideChange = (origin, destination) => {
    console.log(
      `Navigated from section ${origin.index} to section ${destination.index}`
      );
      seActiveDot(destination.index);
  };
  return (
    <>
    {
      activeDot==1?(
      <style>{`
        #fp-nav ul li a span {
          background: #fff !important;
        }
        `}</style>
        ):activeDot==4?(<style>{`
        #fp-nav ul li a span {
          background: #fff !important;
        }
        `}</style>):""
    }
      <Header />

      <main>
        <ReactFullpage
          onLeave={handleSlideChange}
          navigation
          sectionsColor={["#f2f2f2", "#4BBFC3", "#7BAABE"]}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <section className="section section_scroll hero_sec_story">
                  <div className="story_contant container">
                    <div className="row">
                      <div className="col-md-6 height_responsive">
                        <div className="story_contant_hedding">
                          <Fade bottom>
                            <h4>
                              Dreams fuel Success,{" "}
                              <span>Actions make them a reality</span>
                            </h4>
                          </Fade>
                          <Fade bottom>
                            <p>
                              Progress has never been just a mere dream for me,
                              but rather a far-off reality that constantly
                              presents new milestones to be achieved. With this
                              belief in mind, I founded Crocent International, a
                              company specialising in information
                              technology. Crocent International is working
                              quickly to gain recognition in its industry. As an
                              entrepreneur, I always aspired to surpass previous
                              achievements and strive for greater success.
                              Rather than simply being a global player, I
                              envisioned Crocent as a company capable of making
                              a positive impact with its high-quality,
                              competitively business solutions. Despite the
                              challenges inherent in such a specialized and
                              technically demanding industry, I chose to expand
                              Crocent, charting a course less travelled. 
                            </p>
                          </Fade>
                          <Fade bottom>
                            <p>
                              Thanks to the exceptional expertise and support of
                              my team, we have successfully brought Crocent to a
                              new level of recognition. The journey of Crocent
                              is far from over, but with the continued support
                              of our clients and team, I am confident that we
                              will continue to achieve milestones of excellence
                              for years to come.
                            </p>
                          </Fade>
                        </div>
                      </div>

                      <div className="col-md-5 height_responsive">
                        <div className="akshit">
                          <Fade bottom>
                            <img src="assets/akshit.png" alt="" />
                          </Fade>
                          <div className="info">
                            <Fade bottom>
                              <h4>Akshit Aneja</h4>
                              <p>Founder & MD</p>
                            </Fade>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overlay"></div>
                </section>

                <section className="section section_scroll main_contant_fast">
                  <div className="story_contant container">
                    <div className="row">
                      <div className="col-md-6 height_responsive">
                        <div className="story_contant_hedding2">
                          <Fade bottom>
                            <h4>
                              Leaders lead people to <br />
                              <span>do the “IMPOSSIBLE”.</span>
                            </h4>
                          </Fade>
                          <Fade bottom>
                            <p>
                              As the leader of this company, I have always been
                              driven to achieve greater success. With a talented
                              team and ample resources, I am confident that we
                              will continue to reach new heights. We have
                              reached, thus, far owing to the hard work,
                              dedication and determination of the team that
                              drives this organization.
                            </p>
                          </Fade>
                          <Fade bottom>
                            <p>
                              Our team is constantly pushing the boundaries and
                              exploring new opportunities for growth and
                              expansion. And as we move forward, I am confident
                              that our team's passion, creativity, and
                              unwavering commitment to excellence will enable us
                              to achieve even greater success in the years to
                              come. Together, we will continue to build a legacy
                              of excellence and make a meaningful impact in the
                              world.
                            </p>
                          </Fade>
                        </div>
                      </div>

                      <div className="col-md-5 height_responsive">
                        <div className="gurbinder_singh">
                          <Fade bottom>
                            <img src="assets/gurbinder_singh2.png" alt="" />
                          </Fade>
                          <div className="info">
                            <Fade bottom>
                              <h4>Gurbinder Singh</h4>
                              <p>Co- Founder & Director</p>
                            </Fade>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overlay2"></div>
                </section>

                <section className="section section_scroll main_contant_second">
                  <div className="story_contant container">
                    <div className="row">
                      <div className="col-md-7 height_responsive">
                        <div className="story_contant_hedding3">
                          <Fade bottom>
                            <h4>
                              "Where new ideas develop, creativity
                              <br />
                              <span>continues, Crocent follows." </span>
                            </h4>
                          </Fade>
                          <Fade bottom>
                            <p>
                              Creativity is one of the most important aspects of
                              any successful company, and it starts with the
                              generation of ideas. At the heart of our company
                              is a culture that values innovation and
                              experimentation. We prioritize creativity and are
                              willing to take risks, explore new ideas, and
                              challenge the status quo. Our biggest assets have
                              always been our small but highly trained team of
                              professionals who are backed by a very able team
                              of administrators.
                            </p>
                          </Fade>
                          <Fade bottom>
                            <p>
                              Our vision is clear about building our strengths
                              and our achievements while holding dear to us our
                              basic principles. The journey of the company is
                              never ending. With the support of our team and
                              clients, we are confident that the company will
                              continue to scale milestones of excellence for
                              years to come.
                            </p>
                          </Fade>
                          <div info_auto="">
                            <div info1="">
                              <Fade bottom>
                                <img src="assets/signature_akshit.png" alt="" />
                                <h4>Akshit Aneja</h4>
                                <p>Founder & MD</p>
                              </Fade>
                            </div>
                            <div info1="">
                              <Fade bottom>
                                <img src="assets/gurbinder_sign.png" alt="" />
                                <h4>Gurbinder Singh</h4>
                                <p>Co- Founder & Director</p>
                              </Fade>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 height_responsive">
                        <div className="gurbinder_singh_akshit">
                          <Fade bottom>
                            <img src="assets/gurbinder2.png" alt="" />
                          </Fade>
                          <Fade right>
                            <img akshit="" src="assets/akshit1.png" alt="" />
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overlay3"></div>
                </section>

                <section className="section section_scroll main_contant_three">
                  <div className="story_contant ">
                    <div heading="true">
                      <Fade bottom>
                        <h4>
                          our <span>team our strength</span>
                        </h4>
                      </Fade>
                      <Fade bottom>
                        <p>
                          We pride ourselves on delivering high-quality IT
                          solutions that are cost-effective, reliable, and
                          scalable, enabling businesses to focus on their core
                          operations while leaving the technology to us. 
                        </p>
                      </Fade>
                    </div>

                    <div team="true">
                      <Fade bottom>
                        <img src="assets/group_left.png" alt="" />
                      </Fade>
                      <Fade left>
                        <img src="assets/Gurbinder.png" alt="" />
                      </Fade>
                      <Fade right>
                        <img src="assets/akshit_wite.png" alt="" />
                      </Fade>
                      <Fade bottom>
                        <img src="assets/group_right.png" alt="" />
                      </Fade>
                    </div>
                  </div>

                  <div className="overlay3"></div>
                </section>

                <section className="section section_scroll main_contant_four">
                  <div main="true" className="story_contant height_auto container">
                    <div className="row">
                      <div className="col-md-6 ">
                        <div goal_heading="true">
                          <Fade bottom>
                            <h4>
                              OUR <span>FUTURE Goals</span>
                            </h4>
                          </Fade>
                          <Fade bottom>
                            <p>
                              {" "}
                              We strive to shape a better tomorrow through
                              innovative IT solutions. Our primary objective is
                              to leverage technology to drive business growth
                              and foster digital transformation. We are
                              committed to staying at the forefront of industry
                              advancements, offering cutting-edge IT solutions.
                              With a strong focus on delivering exceptional
                              customer experiences, we aim to empower
                              organizations with the tools and expertise
                              necessary to thrive in the digital age.
                            </p>
                            <p>
                              Our team of skilled professionals is dedicated to
                              providing reliable and scalable IT solutions
                              tailored to our clients' unique needs. Join us on
                              this journey towards a brighter future powered by
                              technology.
                            </p>{" "}
                            {/* <a href="">-JOHN DOE</a> */}
                          </Fade>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="">
                          <div comp_logo="true"></div>
                          <Fade bottom>
                            <img
                              comp_logo_img="true"
                              src="assets/comp_logo.png"
                              alt=""
                            />
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overlay4"></div>
                  <Footer />
                </section>

                <div></div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </main>
    </>
  );
};

export default CrocentStory;
