import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Fade from "react-reveal/Fade";
import data from "../../json/ratting.json";
const Testimonials = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [jsonData, setJsonData] = useState([]);
  const [initialItemCount, setInitialItemCount] = useState(4);
  const [itemsPerLoad, setItemsPerLoad] = useState(4);
  const memberDataRef = useRef(null);

  useEffect(() => {
    // Load initial data from the JSON file
    setJsonData(data.slice(0, initialItemCount));
  }, [initialItemCount]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nextIndex = jsonData.length + itemsPerLoad;
        setJsonData(data.slice(0, nextIndex));
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "200px",
      threshold: 0,
    });

    const memberDataDiv = memberDataRef.current;
    if (memberDataDiv) {
      observer.observe(memberDataDiv);
    }

    return () => {
      if (memberDataDiv) {
        observer.unobserve(memberDataDiv);
      }
    };
  }, [jsonData, itemsPerLoad]);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Testimonials</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">What Client Says About Us</h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
      <section className=" mt_100 container">


      <Fade top>
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">Our</span> TESTIMONIALS
              </h2>
              <p>
                Check out our clients testimonials to hear directly from the
                marketers and business owners who’ve worked with us.
              </p>
            </div>
          </Fade>
          </section>
        <section className="testimonials">
          <div className="row container">
            <div className="col-md-6 ">
              <Fade left>
               
                <div className="image_testimonials">
                  <img
                    className="testimonials_images"
                    src="assets/review.png"
                    alt=""
                  />

                  <img
                    className="review_bg"
                    src="assets/review_bg.png"
                    alt=""
                  />
                  <div className="div_cercle">
                    <span>
                      <img src="assets/quwate.png" alt="" />
                    </span>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-md-6">
              {/* <div className="view_all_btn">
                <Link to="/testimonials" className="view_all_testimonial">
                  View All
                </Link>
              </div> */}
              <Fade right>
                <div className="containsstest containsstest22">
                  <a href="">- John Smith</a>
                  <p>
                    It has been a wonderful experience working with you. We got
                    our app developed on time and it’s functioning is also very
                    impressive. The experience of working with Crocent, was a
                    wonderful one. They have always been reliable in delivering
                    the results. We treat each other as business partners,
                    rather than just clients.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="our_team mt_140 container">

          <div className="row" ref={memberDataRef} style={{ overflow: "auto" }}>
            {jsonData.map((item) => (
              <div id="reviewdiv" className="col-md-6">
                <Fade top>
                  <div className=" card-review shadow-sm ">
                    <div className="body">
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          defaultValue={item.defaultValue}
                          precision={item.precision}
                          readOnly
                        />
                      </Stack>
                      <p>{item.feedback}</p>
                    </div>

                    <div className="review_footer">
                      <img src={item.image} alt="" />
                      <div className="review-name">
                        <h5 className="name">{item.name}</h5>
                        {/* <p className="headline">{item.type}</p> */}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            ))}

            <Fade top>
              <div className="allviewblog">
                <button className="btn btn-outline-custom market-btn">
                  View All
                </button>
              </div>
            </Fade>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Testimonials;
