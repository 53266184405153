import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import { Collapse } from "antd";
import Footer from "../common/Footer";
import Gettouch from "../common/Gettouch";
import { BiPhoneCall } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import Fade from "react-reveal/Fade";
const Contact = () => {
  const { Panel } = Collapse;
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    document.title = "Contact - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Contact Us</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">Contact Us For Your Project</h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="get_in_touch_contact">
          <div className="row container">
            <div className="col-md-6">
              <div className="get_in_from_contact">
                <Fade top>
                  <div className="touch_contain">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">GET</span> IN TOUCH
                    </h2>
                    <p className="about-us-hedding">
                      Whether it is designing, development, multimedia or app
                      development, we are here for you. So, what are you waiting
                      for? Fill out the form and we’ll discuss your project.
                    </p>
                  </div>
                </Fade>

                <Fade top>
                  <Gettouch />
                </Fade>
              </div>
            </div>

            <div className="col-md-6 linecontact">
              <Fade right>
                <div className="get_in_touch_details">
                  <ul>
                    <li>
                      <h5>Call Us</h5>

                      <p>
                        Experience the power of seamless technology solutions.
                        Call us today and unlock the full potential of your
                        business with our IT expertise.
                      </p>
                      <a href="">
                        {" "}
                        <span>
                          <BiPhoneCall />{" "}
                        </span>{" "}
                       <p> +91 98887 47538</p>
                      </a>
                    </li>
                    <li>
                      <h5>Visit Us</h5>

                      <p>
                        Embark on a transformative journey into the realm of
                        cutting-edge technology. Visit our office and witness
                        the future of innovation firsthand.
                      </p>
                      <a href="">
                        {" "}
                        <span>
                          <GoLocation />{" "}
                        </span>{" "}
                      
                       <p> Office No - 101, Plot No: E-299, Industrial Area Phase
                        8A , Sector 75, SAS Nagar, Punjab 160075</p>
                       
                      </a>
                    </li>
                    <li>
                      <h5>Email Us</h5>

                      <p>
                        Have a question or need assistance? Reach out to us via
                        email and let our IT experts provide you with the prompt
                        and comprehensive support you deserve.
                      </p>
                      <a href="">
                        {" "}
                        <span>
                          <AiOutlineMail />{" "}
                        </span>{" "}
                       <p> hello@crocentinternational.com</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="map_conatact">
          <div className="container">
            <Fade top>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13722.292326810159!2d76.67457799215569!3d30.702285717131392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feefc7d267a17%3A0x411487d765ddd71c!2sSector%2075%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab!5e0!3m2!1sen!2sin!4v1678872975325!5m2!1sen!2sin"
                width="840"
                height="350"
              ></iframe>
              <h3>Find Us On Map!</h3>
            </Fade>
          </div>
        </section>

        {/* <section className="faq_contact">
          <div className="faqs container">
            <Fade top>
              <div className="team_hedding ">
                <h2>
                  <span className="text_yellow">frequently</span> asked question
                </h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem <br /> Ipsum has been the
                  industry's standard dummy text ever since.
                </p>
              </div>
            </Fade>
            <div className="row">
              <div className="col-md-6">
                <Fade top>
                  <Collapse>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="1"
                    >
                      <p>
                        Designed to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="2"
                    >
                      <p>
                        TDesigned to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="3"
                    >
                      <p>
                        TDesigned to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                  </Collapse>
                </Fade>
              </div>
              <div className="col-md-6">
                <Fade top>
                  <Collapse>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="1"
                    >
                      <p>
                        Designed to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="2"
                    >
                      <p>
                        TDesigned to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                    <Panel
                      header="Lorem ipsum dolor sit amet consectetur adipisicing."
                      key="3"
                    >
                      <p>
                        TDesigned to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                  </Collapse>
                </Fade>
              </div>
            </div>
          </div>
        </section> */}
      </main>

      <Footer />
    </>
  );
};

export default Contact;
