import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import Roll from "react-reveal/Roll";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const WorkingCrocentInternational = () => {
  useEffect(() => {
    document.title = "Apply At Crocent International - Crocent International";
  }, []);
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Working At Crocent International</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">
                      Opportunity to change the world
                    </h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="market-h  mt_100">
          <div className="container">
            <div className="row_ind">
              <div className="card2">
                <Fade left>
                  <div className="image_design_ind image_design_ind2">
                    <Shimmer>
                      <LazyLoadImage
                        src="assets/working-crocent-international.png"
                        alt="Image Alt"
                        effect="blur"
                        className="thumb"
                      />
                    </Shimmer>

                    <div className="backround_ind2"></div>
                  </div>
                </Fade>
              </div>
              <div className="card1">
                <Fade right>
                  <div className="contain_design_ind">
                    <h4 className="mainh4">
                      <b className="text_yellow">Making Dreams </b> <br /> Come True
                    </h4>
                    <p>
                      We believe that everyone has unique aspirations and
                      ambitions, and we are dedicated to helping them achieve
                      their goals. Our team is passionate about making a
                      positive impact on people's lives by providing exceptional
                      service and support. As a member of our team, you will
                      have the chance to work in a dynamic and rewarding
                      environment where you can make a real difference.
                    </p>
                    <p>
                      Whether you are interested in customer service, sales,
                      marketing, or operations, we have a variety of positions
                      available that allow you to use your skills and talents to
                      help others reach their dreams. 
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section what_matters="true">
          <div className="container">
            <div what_matters_hedding="true">
              <h2>
                <span className="text_yellow">People </span> are what matters
              </h2>
              <p>
                We strive to create a supportive and inclusive workplace where
                every <br /> employee can thrive and reach their full potential.
              </p>
            </div>

            <div what_matters_contain="true">
              <div className="images_what_matter">
                <div flex_what_matter="true" className="row">
                  <div className="col-md-5">
                <img class="rigth_cercle" src="assets/rigth_cercle.png" alt="" />
                    <div className="cart_what_matter win_video">
                      <Shimmer>
                        <LazyLoadImage
                          src="assets/what_matter_video.png"
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Shimmer>
                    
                      <img class="play2" src="assets/play.png" alt="" />
                      <img class="yello__dot" src="assets/yello-dot.png" alt="" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="cart_what_matter">
                    
                      <Shimmer>
                      <LazyLoadImage
                      src="assets/water_what.png"
                        alt="Image Alt"
                        effect="blur"
                      />
                    </Shimmer>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="cart_what_matter">
                  
                      <Shimmer>
                      <LazyLoadImage
                      src="assets/coffee_girl.png"
                        alt="Image Alt"
                        effect="blur"
                      />
                      <img
                      src="assets/wt_dot.png"
                        alt="Image Alt"
                        className="cercle_effect"
                      
                      />
                    </Shimmer>
                    </div>
                  </div>
                </div>

                <div className="layer_tow">
                  <div className="img1">
                  <Shimmer>
                      <LazyLoadImage
                      src="assets/group_boy.png"
                        alt="Image Alt"
                        effect="blur"
                      />
                    </Shimmer>
                  
                  </div>
                  <div className="img2">
                  <Shimmer>
                      <LazyLoadImage
                      src="assets/water_phool.png"
                        alt="Image Alt"
                        effect="blur"
              
                      />
                    </Shimmer>
                 
                  </div>
                  <div className="img3">
                  <Shimmer>
                      <LazyLoadImage
                     src="assets/group_team.png"
                        alt="Image Alt"
                        effect="blur"
              
                      />
                    </Shimmer>
                 
                  </div>
                  <img class="lest__cercle" src="assets/ellipse.png" alt="" />
                  
                </div>
              </div>

              <div quality_div="true">
                <div quality_row="true">
                  <div quality_circle="true">
                    <img src="assets/awesomeofficespace.png" alt="" />
                  </div>
                  <h4>Awesome office space</h4>
                  <p>
                    Large Office Space Designed for Productivity and
                    Collaboration
                  </p>
                </div>

                <div quality_row="true">
                  <div quality_circle="true">
                    <img src="assets/quarterlyevents.png" alt="" />
                  </div>
                  <h4>Quarterly events</h4>
                  <p>
                    Designed to bring together like-minded individuals in a
                    dynamic and engaging environment.
                  </p>
                </div>

                <div quality_row="true">
                  <div quality_circle="true">
                    <img src="assets/HealthWellness.png" alt="" />
                  </div>
                  <h4>Health & Wellness</h4>
                  <p>
                    To empower a journey towards optimal health, providing the
                    tools, knowledge, and support everyone needs to thrive.
                  </p>
                </div>

                <div quality_row="true">
                  <div quality_circle="true">
                    <img src="assets/HappierWeekdays.png" alt="" />
                  </div>
                  <h4>Happier Weekdays</h4>
                  <p>
                    To help everyone find happiness in every moment, even during
                    the busiest of days.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section good_cooperation="true">
          <div className="container">
            <div className="what_matters_container">
              <div className="row">
                <div className="col-md-6">
                  <div className="contain_design_ind">
                    <h4 className="mainh4">
                      <b className="text_yellow">GOOD </b> COOPERATION
                    </h4>
                    <p>
                      At Crocent International, we are passionate about
                      leveraging cutting-edge technology to solve complex
                      business challenges. We foster a culture of collaboration,
                      continuous learning, and innovation, and we strive to
                      create an environment that empowers our employees to
                      achieve their professional goals.
                    </p>
                    <p>
                      Whether you are a seasoned IT professional or just
                      starting your career in tech, we offer exciting
                      opportunities to work on challenging projects and develop
                      your skills alongside some of the best in the industry.
                      Join us and be a part of shaping the future of technology.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="retting_feedback">
                    <div className="fd_grid">
                      <img src="assets/feed_1.png" alt="" />
                      <div className="fdrow">
                        Working at Crocent International  has been an absolute
                        pleasure. The positive work environment and supportive
                        team make each day enjoyable.
                      </div>
                    </div>

                    <div className="fd_grid_right">
                      <div className="fdrow">
                        I've been with Crocent for several years, and I can
                        confidently say that it's the best career decision I've
                        made.
                      </div>
                      <img src="assets/feed_2.png" alt="" />
                    </div>

                    <div className="fd_grid">
                      <img src="assets/feed_3.png" alt="" />
                      <div className="fdrow">
                        The team at Crocent fosters a culture of open
                        communication, transparency, and trust. They value
                        employee opinions, encourage innovation.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="big_images">
       
                    <Shimmer>
                      <LazyLoadImage
                   src="assets/big_images.png"
                        alt="Image Alt"
                        width={'100%'}
                        effect="blur"
                      />
                    </Shimmer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default WorkingCrocentInternational;
