import React from 'react'
import './footer.css';
import { AiOutlineMail } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { FaLocationArrow } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
const Footer = () => {
  return (
    <div className="bg-dark">
      <footer className="row padding-100  py-5  container">
        <div className="col-md-4">
          <Fade bottom>

         
          <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
            <img src="assets/logo.svg" alt="" />
          </a>
          <p className="text-footer_brand">We are one of the leading IT industries delivering professional web services. Our purpose is to imprint value to a brand and long term business growth. We do this by making a difference by taking steps as required from time to time.</p>
          </Fade>
        </div>

        <div className="col mb-4"></div>

        <div className="col-md-3">
        <Fade bottom>
          <h5 className='footer_hedding'><span className="text_yellow">Quick</span> Link</h5>
          <div className="row">
            <div className="col-6">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Home</Link></li>
                <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-muted">About Us</Link></li>
                <li className="nav-item mb-2"><Link to="/working-crocent-international" className="nav-link p-0 text-muted">Our Businesses</Link></li>
                <li className="nav-item mb-2"><Link to="#" className="nav-link p-0 text-muted">Sitemap</Link></li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to="/blogs" className="nav-link p-0 text-muted">Blog</Link></li>
                <li className="nav-item mb-2"><Link to="/privacy-policy" className="nav-link p-0 text-muted">privacy policy </Link></li>
                <li className="nav-item mb-2"><Link to="/terms-and-conditions" className="nav-link p-0 text-muted">termS & conditions </Link></li>
                <li className="nav-item mb-2"><Link to="/testimonials" className="nav-link p-0 text-muted">Testimonial</Link></li>
                <li className="nav-item mb-2"><Link to="/contact-us" className="nav-link p-0 text-muted">Contact</Link></li>
              </ul>
            </div>
          </div>
          </Fade>
        </div>

        <div className="col mb-3"></div>

        <div className="col-md-4">
        <Fade bottom>
          <h5 className='footer_hedding'><span className="text_yellow">Contact</span> US</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2 footer_icoin_grp">
              <FaLocationArrow className='icoinsfooterr' /> <p className='footer_list'>Office No - 101, Plot No: E-299, Industrial Area Phase 8A , Sector 75, SAS Nagar, Punjab 160075</p>
            </li>
            <li className="nav-item mb-2 footer_icoin_grp">

              <AiOutlineMail className='icoinsfooterr' /> <p className='footer_list'>work@crocentinternational.com</p>
            </li>
            <li className="nav-item mb-2 footer_icoin_grp">
              <FiPhoneCall className='icoinsfooterr' /><p className='footer_list'>+91 98887 47538</p>
            </li>
          </ul>

 </Fade>

        </div>
        <Fade bottom>

        <hr className='footer_hr' />
        <p className='footer_copy'>Crocent International © 2022. All Rights Reserved.</p>
        </Fade>
      </footer>
    </div>
  )
}

export default Footer