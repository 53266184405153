import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
const TermsConditions = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Terms and Conditions - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Terms and Conditions</h1>
              </Fade>
            </div>
          </div>
        </section>
      </div>
      <main>
        <section className="mb_100 mt_100 container terms">
          <Fade bottom>
            <h4>Terms</h4>
            <p>
              By accessing this web site, you are agreeing to be bound by these
              web site Terms and Conditions of Use, all applicable laws and
              regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these terms, you are prohibited from using or accessing this site.
              The materials contained in this web site are protected by
              applicable copyright and trade mark law.
            </p>
          </Fade>

          <h4>Licence</h4>
          <p>
            {" "}
            Unless otherwise stated, Crocent International and/or its licensors
            own the intellectual property rights for all material on Crocent
            International. All intellectual property rights are reserved. You
            may access this from Crocent International for your own personal use
            subjected to restrictions set in these terms and conditions.
          </p>
          <p>
            {" "}
            <p>You must not:</p>
          </p>
          <ul>
            <li>
              <p>Republish material from Crocent International</p>
            </li>
            <li>
              <p>
                Sell, rent or sub-license material from Crocent International
              </p>
            </li>
            <li>
              <p>
                Reproduce, duplicate or copy material from Crocent International
              </p>
            </li>
            <li>
              <p>Redistribute content from Crocent International</p>
            </li>
          </ul>

          <h4>Hyperlinking </h4>
          <p>
            {" "}
            The following organisations may link to our Website without prior
            written approval:
          </p>

          <ul>
            <li>
              <p>Government agencies;</p>
            </li>
            <li>
              <p>Search engines;</p>
            </li>
            <li>
              <p>News organisations;</p>
            </li>
            <li>
              <p>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </p>
            </li>
            <li>
              <p>
                System wide Accredited Businesses except soliciting non-profit
                organisations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Website.
              </p>
            </li>
          </ul>

          <h4>Disclaimer of Warranties</h4>
          <p>
            We make no representations or warranties of any kind, express or
            implied, about the completeness, accuracy, reliability, suitability,
            or availability of our website or services. Your use of our website
            and services is at your own risk, and we will not be liable for any
            damages arising from your use of our website or services.
          </p>

          <h5>1. Termination</h5>
          <ol>
            <li>
              <p>
                We may terminate or suspend your access to our website or
                services at any time, with or without cause, and without notice
                or liability to you. Upon termination, you must immediately
                cease all use of our website and services.
              </p>
            </li>
          </ol>
          <h5>2. Governing Law</h5>
          <ol>
            <li>
              <p>
                These terms and your use of our website and services shall be
                governed by and construed in accordance with the laws of the
                India Contracts Act 1872 (ICA), without giving effect to any
                principles of conflicts of law.
              </p>
            </li>
          </ol>
          <h5>3. Changes to These Terms</h5>
          <ol>
            <li>
              <p>
                We may update these terms from time to time to reflect changes
                in our business or legal requirements. We encourage you to
                review these terms periodically for any updates.
              </p>
            </li>
          </ol>
          <h5>4. Contact Us</h5>
          <ol>
            <li>
              <p>
                If you have any questions or concerns about these terms or our
                services, please contact us at <a href="mailto:work@crocentinternational.com">work@crocentinternational.com</a>.
              </p>
            </li>
          </ol>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default TermsConditions;
