import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import "./home.css";
import data from "../../json/team.json";
import Footer from "../common/Footer";
import { AiOutlineArrowRight } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import Companylogo from "../common/Companylogo";
import Shimmer from "react-shimmer-effect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const About = () => {
  const [jsonData, setJsonData] = useState([]);
  const [initialItemCount, setInitialItemCount] = useState(4);
  const [itemsPerLoad, setItemsPerLoad] = useState(4);
  const memberDataRef = useRef(null);
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Load initial data from the JSON file
    setJsonData(data.slice(0, initialItemCount));
  }, [initialItemCount]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nextIndex = jsonData.length + itemsPerLoad;
        setJsonData(data.slice(0, nextIndex));
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "200px",
      threshold: 0,
    });

    const memberDataDiv = memberDataRef.current;
    if (memberDataDiv) {
      observer.observe(memberDataDiv);
    }

    return () => {
      if (memberDataDiv) {
        observer.unobserve(memberDataDiv);
      }
    };
  }, [jsonData, itemsPerLoad]);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">About Us</h1>

                <div className="row-custom">
                  <div className="col-custom">
                    <h2 className="heading-lg">A Journey of Passion & Purpose</h2>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </section>
      </div>

      <main>
        <section className="container mt_100">
          <div className="about-us container overflow-hidden">
            <div className="row ">
              <div className="col-md-6">
                <Fade left>
                  <div className="client-1">
                    <img src="assets/client.png" alt="" />
                    <img
                      className="about_us_white_prt"
                      src="assets/about_us_white_prt.png"
                      alt=""
                    />
                  </div>
                  <div className="client-2 about_client_2">
                    <img
                      className="about_client_2"
                      src="assets/client2.png"
                      alt=""
                    />
                    <img
                      className="about_us_yello_prt"
                      src="assets/about_us_yello_prt.png"
                      alt=""
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-md-6">
                <Fade right>
                  <div className="about_contain">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">About</span> us
                    </h2>
                    <p className="about-us-hedding">
                      Crocent International is a team of strategic thinking IT
                      experts who believe in powering up brands and helping
                      businesses to drive the best results across the globe.
                      With an evolving experience and talent in the industry,
                      our team works in creating a lasting business value. Our
                      creative approach towards a wide range of IT business
                      strategies are designed to benefit your business growth.
                      Bringing web, entertainment, and technology under one
                      roof, our solutions converge all expertise to create
                      harmony between them. This enables us to provide solutions
                      that produce real-time, relevant, and measurable results
                      for your brand.
                    </p>
                    <div className="row visson_img_viss_miss">
                      <div className="col">
                        <div className="visson_img">
                          <img src="assets/vision.png" alt="" />
                        </div>
                        <div className="visson_sec">
                          <h4>Our Vision</h4>
                          <p>
                            Empower businesses to achieve their full potential
                            through innovative technology solutions.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                      <div className="mission_img">
                          <img src="assets/mission.png" alt="" />
                        </div>
                        <div className="mission_sec">
                          <h4>Our Mission</h4>
                          <p>
                            Provide high quality creative IT solutions &
                            services across the globe.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className="companylogo">
            <Fade left>
              <Companylogo />
            </Fade>
          </div>
        </section>

        <section className="request_seduele2">
          <hr className="request_seduele_line" />
          <div className="container">
            <Fade left>
              <div className="request_seduele_box">
                <h2>
                  Request a Schedule For <br />
                  Free Consultation
                </h2>
                <button className=""><Link className="text-light" to="/contact-us">Schedule A Consultation</Link></button>
              </div>
            </Fade>
          </div>
        </section>

        <section className="why_choose_about mt_140">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <Fade left>
                  <div className="contains_abouts">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">Why</span> Choose us
                    </h2>
                    <p>
                      Our team of professionals is dedicated to providing
                      exceptional service, personalized attention, and open
                      communication throughout every phase of the project.
                      <br className="" />
                      <br className="" />
                      We pride ourselves on our ability to think creatively,
                      solve problems efficiently, and adapt to changing
                      circumstances. Our company values honesty, integrity, and
                      professionalism in all our dealings, and we believe in
                      building long-lasting relationships with our clients based
                      on trust and mutual respect. Whether you need a customized
                      solution to a complex problem or a simple solution to a
                      common challenge, we have the resources, and passion to
                      help you achieve your goals.
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-6">
                    <Fade top>
                      <div className="image_about">
                        <Shimmer>
                          <LazyLoadImage
                            src="assets/whyus.png"
                            alt="Image Alt"
                            effect="blur"
                          />
                        </Shimmer>
                        <div className="imagebacground">
                          <Link to="/contact-uss" >Contact Us</Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-md-6">
                    <Fade right>
                      <div className="list_about">
                        <ul>
                          <li>
                            Innovation
                            <p>
                              Reach the world through innovative ideas, creative
                              output to generate a rich digital experience for
                              our clients.
                            </p>
                          </li>
                          <li>
                            Expertise
                            <p>
                              Our expertise & professional industry experience
                              allows us to lead in every aspect. In-depth
                              understanding of your business needs, and
                              requirements.
                            </p>
                          </li>
                          <li>
                            Results
                            <p>
                              Effective leadership, Cear communication executing
                              high performing measurable results at all costs.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our_team_about mt_140 ">
          <div className="container ">
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">CROCENT </span> FAMILY
              </h2>
              <p>
                We’re a team of professionals working together to bring you
                creative solutions <br /> that work directly for your brand.
              </p>
            </div>

            <div
              className="row"
              ref={memberDataRef}
              style={{ overflow: "auto" }}
            >
              {jsonData.map((item) => (
                <div className="col-md-3">
                  <Fade top>
                    <div className="card-team shadow-sm shadow-sm bg-white">
                      <Shimmer>
                        <LazyLoadImage
                          src={item.image}
                          width={"100%"}
                          height={"100%"}
                          alt="Image Alt"
                          effect="blur"
                        />
                      </Shimmer>

                      <div className="cart_footer">
                        <h5 className="name">{item.name}</h5>
                        <p className="headline">{item.type}</p>
                      </div>
                      {/* <div className="aarrows">
                        <AiOutlineArrowRight />
                      </div> */}
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="awrod_win mt_100 mb_100">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="contacn_win">
                  <Fade left>
                    <h4 className="hedding">
                      <span className="text_yellow">Award</span> Winning
                    </h4>
                  </Fade>
                  <Fade left>
                    <div className="contant_card">
                      <p>
                        Our company has a proven track record of delivering
                        outstanding results for our clients. We are honoured to
                        have been recognized by industry experts and peers for
                        our exceptional work, which has included projects in
                        diverse sectors such as entertainment, technology, and
                        more. Our success is built on a foundation of
                        excellence, integrity, and customer focus. 
                      </p>
                      <a href="/contact-us">Contact Us</a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="col-md-6">
                <Fade right>
                  <div className="win_video">
                    <Shimmer>
                      <LazyLoadImage
                        src="assets/thumb.png"
                        alt="Image Alt"
                        effect="blur"
                        className="thumb"
                      />
                    </Shimmer>

                    <img className="play" src="assets/play.png" alt="" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default About;
