import React, { useEffect } from "react";
import Header from "../common/Header";
import "./home.css";
import "./app_min.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import Spin from "react-reveal/Spin";
const Gymella = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Gymella - Crocent International";
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="hero-app banner banner-section">
          <div className="sectionimage_app ">
            <div className="row container">
              <div className="col-md-6 cst_order2">
                <Fade left>
                  <div className="contain_all_dwonloadapp_app">
                    <h5 className="h5">
                      <span>GET OUR APP </span> <br />
                      GYMELLA
                    </h5>
                    <p>
                      Gymella is a fitness app designed to help users achieve
                      their fitness goals by providing personalised workout
                      plans and tracking tools anywhere and everywhere. The app
                      offers a wide variety of workout routines, including
                      cardio, and yoga, which can be customised based on the
                      user's fitness level and goals. Download a comprehensive
                      fitness app to stay motivated and achieve your fitness
                      goals.
                    </p>
                    <div className="app_store">
                      <div className="d">
                        <img src="assets/phone/play.png" alt="" />
                        <img src="assets/phone/apple.png" alt="" />
                      </div>

                      <img
                        className="download_qr"
                        src="assets/phone/demo_qr.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Fade>
              </div>

              <div className="col-md-6 cst_order1">
                <Fade right>
                  <div className="image_container_app">
                    <div className="phone_sec">
                      <img
                        src="assets/phone/gymilla_pn.png"
                        alt=""
                        className="phone"
                      />{" "}
                      <Spin>
                        <img
                          src="assets/phone/gymilla.png"
                          alt=""
                          className="phone_bg"
                        />
                      </Spin>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>

        <section className="section_option_apps">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>01</span>
                    </div>
                    <div className="container_card">
                      <h4>Personalised Workout Plans</h4>
                      <p>
                      Customised workout plans based on the user's fitness goals, experience level, and preferences. 

                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>02</span>
                    </div>
                    <div className="container_card">
                      <h4>Virtual Trainer</h4>
                      <p>
                        Access to virtual trainers and coaches offering
                        personalised guidance, answer to questions, and feedback
                        on form and technique.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-4">
                <Fade bottom>
                  <div className="personalised_card">
                    <div className="number_card_box">
                      <span>03</span>
                    </div>
                    <div className="container_card">
                      <h4>In-app Messaging</h4>
                      <p>
                        In-app messaging system for inquiries, feedback to
                        facilitate communication between users and trainers.
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Gymella;
