import axios from "axios";
import React from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const notify = () => toast.success("Query sent successfully!");
const notifyerror = () => toast.error("Please fill out all fields!");

const Gettouch = () => {
  const [fastname, setFastName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (
      fastname === "" ||
      lastname === "" ||
      email === "" ||
      number === "" ||
      message === ""
    ) {
      notifyerror();
    } else {
      let data = new FormData();
      data.append("fast_name", fastname);
      data.append("last_name", lastname);
      data.append("email", email);
      data.append("phone", number);
      data.append("message", message);
      data.append("website", "crocentinternational.com");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://admin.crocentinternational.com/api/user_query",
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setStatus(true);
          notify();
          setFastName("");
          setLastName("");
          setEmail("");
          setNumber("");
          setMessage("");
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <form onSubmit={submitForm}>
      <div className="row">
        <div className="col-md-6">
          <input
            type="text"
            value={fastname}
            onChange={(e) => setFastName(e.target.value)}
            className="get_in_from_cont"
            placeholder="Fast Name"
          />
        </div>
        <div className="col-md-6">
          <input
            type="text"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            className="get_in_from_cont wf_full"
            placeholder="Last Name"
          />
        </div>
      </div>
      <div className="row mtrs-5">
        <div className="col-md-6">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="get_in_from_cont"
            placeholder="Email Address"
          />
        </div>
        <div className="col-md-6">
          <input
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="get_in_from_cont wf_full"
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div className="row mtrs-5">
        <div className="col">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="get_in_from_cont git_message wf_full"
            placeholder="Message"
          />
          {status == true ? (
            <p className="text-success_msg">
              Thanks you very much for reaching out to us, We will get back to
              you as soon as possible!
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mt-5">
        <button type="submit" className="get_in_btn">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Gettouch;
