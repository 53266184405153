import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./home.css";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import { Collapse } from "antd";
import data from "../../json/team.json";
const Team = () => {
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const { Panel } = Collapse;
  const [jsonData, setJsonData] = useState([]);
  const [initialItemCount, setInitialItemCount] = useState(4);
  const [itemsPerLoad, setItemsPerLoad] = useState(4);
  const memberDataRef = useRef(null);

  useEffect(() => {
    // Load initial data from the JSON file
    setJsonData(data.slice(0, initialItemCount));
  }, [initialItemCount]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nextIndex = jsonData.length + itemsPerLoad;
        setJsonData(data.slice(0, nextIndex));
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "200px",
      threshold: 0,
    });

    const memberDataDiv = memberDataRef.current;
    if (memberDataDiv) {
      observer.observe(memberDataDiv);
    }

    return () => {
      if (memberDataDiv) {
        observer.unobserve(memberDataDiv);
      }
    };
  }, [jsonData, itemsPerLoad]);

  return (
    <>
      <Header />
      <div className="hero-sec2">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Team</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">Meet With Expert Team</h2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <main>
        <section className="our_team mt_140 container">
          <Fade top>
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">CROCENT </span> FAMILY
              </h2>
              <p>
                We’re a team of professionals working together to bring you
                creative solutions <br /> that work directly for your brand.
              </p>
            </div>
          </Fade>
          <div className="row" ref={memberDataRef} style={{ overflow: "auto" }}>
            {jsonData.map((item) => (
              <div className="col-md-3">
                <Fade top>
                  <div className="card-team shadow-sm shadow-sm bg-white">
                    <img src={item.image} alt="" />
                    <div className="cart_footer">
                      <h5 className="name">{item.name}</h5>
                      <p className="headline">{item.type}</p>
                    </div>
                    <div className="aarrows">
                      <AiOutlineArrowRight />
                    </div>
                  </div>
                </Fade>
              </div>
            ))}
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Team;
