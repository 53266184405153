import React, { useEffect } from "react";
import Header from "../common/Header";
import {
  BsArrowLeft,
  BsArrowLeftShort,
  BsArrowRight,
  BsArrowRightShort,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";
import { BiLogoFacebook } from "react-icons/bi";
import {
  FaAngleRight,
  FaFacebookF,
  FaLinkedinIn,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import team_data from "../../json/team.json";
import ratting_data from "../../json/ratting.json";
import Rotate from "react-reveal/Rotate";
import Footer from "../common/Footer";
import Fade from "react-reveal/Fade";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
import Gettouch from "../common/Gettouch";
import Companylogo from "../common/Companylogo";
import Roll from "react-reveal/Roll";
import LightSpeed from "react-reveal/LightSpeed";
import CountUp from "react-countup";
import { Counter } from "react-render-counter";
import { Shimmer } from "react-shimmer";
import { useState } from "react";
import axios from "axios";
const Home = () => {
  // React.useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])

  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const scrollLeft = () => {
    setTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const scrollRight = () => {
    setTestimonialIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const testimonials = ratting_data;

  const [jsonData, setJsonData] = useState([]);
  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://admin.crocentinternational.com/blog/recent_blog/crocentinternational.com"
      );
      setData(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.title = "Home - Crocent International";
    setJsonData(team_data);
    fetchData();
  }, []);

  const { Panel } = Collapse;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Header />
      <div className="hero-sec">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <div className="banner-inner">
              <Fade left>
                <h1 className="heading-xl">Creative Solutions</h1>
              </Fade>
              <div className="row-custom">
                <div className="col-custom">
                  <Fade left>
                    <h2 className="heading-lg">To Grow Your Business</h2>
                  </Fade>
                  <div className="contain">
                    <Fade right>
                      <p className="contain-hedding">
                        Driven by your business objectives, focused on unique
                        and innovative approaches to digitally transform your
                        business.
                      </p>
                      <Link to="/contact-us">
                        <button className="btn btn-outline-custom">
                          Get Started
                        </button>
                      </Link>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <Fade right>
              <div className="banner-links">
                <Link to="/" title="">
                  <FaFacebookF />{" "}
                </Link>
                <Link to="/" title="">
                  <BsTwitter />{" "}
                </Link>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/crocent-international/"
                  title=""
                >
                  <FaLinkedinIn />{" "}
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/crocentinternational/"
                  title=""
                >
                  <BsInstagram />{" "}
                </a>
              </div>
            </Fade>
          </div>
        </section>
      </div>

      <main>
        <section className="container mt_100">
          <LightSpeed left>
            <h2 className="sub_heading">
              {" "}
              <span className="text_yellow"> Why</span> Choose us
            </h2>
          </LightSpeed>

          <div className="mt_140 ">
            <div className="row">
              <div className="col d-flex">
                <Fade left>
                  <div className="box">
                    <span className="number">1</span>
                    <div className="triangle"></div>
                    <span className="Step">Step</span>
                  </div>

                  <div className="box-contain">
                    <h3>Inovation</h3>
                    <p>
                      Reach the world through innovative ideas, creative output
                      to generate a rich digital experience for our clients.
                    </p>

                    <div className="arrow-up"></div>
                  </div>
                </Fade>
              </div>
              <div className="col d-flex">
                <Fade bottom>
                  <div className="box">
                    <span className="number2">2</span>
                    <div className="triangle2"></div>
                    <span className="Step2">Step</span>
                  </div>
                  <div className="box-contain">
                    <h3>Expertise</h3>
                    <p>
                      Our expertise & professional industry experience allows us
                      to lead in every aspect. In-depth understanding of your
                      business needs, and requirements.
                    </p>
                    <div className="arrow-down"></div>
                  </div>
                </Fade>
              </div>

              <div className="col d-flex">
                <Fade right>
                  <div className="box">
                    <span className="number3">3</span>
                    <div className="triangle3"></div>
                    <span className="Step3">Step</span>
                  </div>

                  <div className="box-contain">
                    <h3>Results</h3>
                    <p>
                      Effective leadership, Cear communication executing high
                      performing measurable results at all costs.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className="about-us container ">
            <div className="row ">
              <div className="col-md-6">
                <Fade left>
                  <div className="client-1">
                    <img src="assets/client.png" alt="" />
                    <img
                      className="about_us_white_prt"
                      src="assets/about_us_white_prt.png"
                      alt=""
                    />
                  </div>
                  <div className="client-2">
                    <img src="assets/client2.png" alt="" />
                    <img
                      className="about_us_yello_prt"
                      src="assets/about_us_yello_prt.png"
                      alt=""
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-md-6">
                <div className="cercle_about_images">
                  <img
                    className="about_us_dark_prt"
                    src="assets/about_us_dark_prt.png"
                    alt=""
                  />
                  <img
                    className="about_us_white_cercle"
                    src="assets/about_us_white_cercle.png"
                    alt=""
                  />
                </div>
                <Fade right>
                  <div className="about_contain">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">About</span> us
                    </h2>
                    <p className="about-us-hedding">
                      Crocent International is a team of strategic thinking IT
                      experts who believe in powering up brands and helping
                      businesses to drive the best results across the globe.
                      With an evolving experience and talent in the industry,
                      our team works in creating a lasting business value. Our
                      creative approach towards a wide range of IT business
                      strategies are designed to benefit your business growth.
                      Bringing web, entertainment, and technology under one
                      roof, our solutions converge all expertise to create
                      harmony between them. This enables us to provide solutions
                      that produce real-time, relevant, and measurable results
                      for your brand.
                    </p>

                    <Link to="/about">
                      <button className="btn btn-outline-custom2">
                        Read More
                      </button>
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </div>

          <div className="compani_logo">
            <Fade left>
              <Companylogo />
            </Fade>
          </div>
        </section>

        <section className="market-indrustry">
          <div className="container market-container">
            <div className="body-market">
              <div className="row">
                <div className="col-sm-6">
                  <Roll left>
                    <div className="hedding-market">
                      <h2>
                        <span className="text_yellow">industries</span> WE WORK
                      </h2>
                      <p>
                        We’re focused on covering all areas of IT services from
                        web services, entertainment to hosting services.
                      </p>
                    </div>

                    <div className="card-market market1">
                      <img src="assets/market/market2.png" alt="" />

                      <div className="market-footer1">
                        <h5>Web Services</h5>
                        <p>
                          We help your business build, host, and manage their
                          websites and applications online. These services can
                          range from basic website builders to complex managed
                          applications with advanced...
                        </p>
                        <Link to="/industries">
                          Visit &nbsp; Website <FaAngleRight />
                        </Link>
                      </div>
                    </div>
                  </Roll>
                </div>

                <div className="col-sm-6">
                  <Roll right>
                    <div className="card-market market2">
                      <img src="assets/market/market1.png" alt="" />
                      <div className="market-footer2">
                        <h5>Ecommerce</h5>
                        <p>
                          Creating visually stunning and user-friendly Ecommerce
                          websites that are tailored to brand and optimized for
                          conversions.
                        </p>
                        <Link to="/industries">
                          Visit &nbsp; Website <FaAngleRight />
                        </Link>
                      </div>
                    </div>
                  </Roll>
                </div>

                <div className="col-sm-6">
                  <Roll left>
                    <div className="card-market market3">
                      <img src="assets/market/market4.png" alt="" />
                      <div className="market-footer3">
                        <h5>entertainment</h5>
                        <p>
                          Our entertainment services include live talks, short
                          movies and series that provide amusement to
                          consumers...
                        </p>
                        <Link to="/industries">
                          Visit&nbsp; Website <FaAngleRight />
                        </Link>
                      </div>
                      {/* <button className="btn btn-outline-custom market-btn">Read More</button> */}
                    </div>
                  </Roll>
                </div>

                <div className="col-sm-6 height_fit">
                  <Roll right>
                    <div className="card-market market4">
                      <img src="assets/market/market3.png" alt="" />
                      <div className="market-footer4">
                        <h5>Hosting</h5>
                        <p>
                          Our hosting service allows businesses to store their
                          website files on a server that can be accessed by
                          users over the internet.
                        </p>
                        <Link to="/industries">
                          Visit&nbsp; Website <FaAngleRight />
                        </Link>
                      </div>
                    </div>
                  </Roll>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="creative">
          <div className="row container">
            <div className="col-md-6">
              <div className="crative_contain">
                <Roll right>
                  <div className="creattive_hedding">
                    <h2>
                      <span className="text_yellow">Creative</span> <br />{" "}
                      Solutions
                    </h2>
                  </div>
                </Roll>
                <Fade left>
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIcon={({ isActive }) =>
                      isActive ? <FaMinus /> : <FaPlus />
                    }
                  >
                    <Panel header="Problem-solving" key="1">
                      <p>
                        Designed to address complex or challenging problems,
                        with multiple interrelated components.
                      </p>
                    </Panel>
                    <Panel header="Innovative" key="2" className="">
                      <p>
                        Embracing an innovative approach to redefine
                        possibilities and inspire positive change.
                      </p>
                    </Panel>
                    <Panel header="Outcomes-focused" key="3">
                      <p>
                        With unwavering determination, we are outcome-focused,
                        driven to achieve tangible results and exceed
                        expectations.
                      </p>
                    </Panel>
                  </Collapse>
                </Fade>
              </div>

              <Fade right>
                <div className="btn_section_creative">
                  <Link to="/about">
                    <button className="btn btn-outline-custom market-btn">
                      Read More
                    </button>
                  </Link>
                </div>
              </Fade>
            </div>

            <div className="col-md-6">
              <Fade top>
                <div className="crative_img">
                  <img className="img1" src="assets/crative2.png" alt="" />
                  <img className="img2" src="assets/crative1.png" alt="" />
                  <img
                    className="bg_style_crative_img"
                    src="assets/creative_sol.png"
                    alt=""
                  />
                  <img
                    className="bg_style_crative_img2"
                    src="assets/creative_so2l.png"
                    alt=""
                  />
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="request_seduele">
          <hr className="request_seduele_line" />
          <LightSpeed left>
            <div className="container">
              <div className="request_seduele_box">
                <h2>
                  Request a Schedule For <br />
                  Free Consultation
                </h2>
                <Link to="contact-us">
                  <button className="">Schedule A Consultation</button>
                </Link>
              </div>
            </div>
          </LightSpeed>
        </section>

        <section className="our_team mt_140 container">
          <LightSpeed right>
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">CROCENT </span> FAMILY
              </h2>
              <p>
                We’re a team of professionals working together to bring you
                creative solutions <br /> that work directly for your brand.
              </p>
            </div>
          </LightSpeed>
          <Carousel responsive={responsive}>
            {jsonData.map((item) => (
              <div className="">
                <Fade right>
                  <div className="card-team shadow-sm shadow-sm bg-white">
                    <img src={item.image} alt="" />
                    <div className="cart_footer">
                      <h5 className="name">{item.name}</h5>
                      <p className="headline">{item.type}</p>
                    </div>
                    {/* <div className="aarrows">
                      <AiOutlineArrowRight />
                    </div> */}
                  </div>
                </Fade>
              </div>
            ))}
          </Carousel>
        </section>

        <section className="testimonials">
          <div className="row container">
            <div className="col-md-6 ">
              <Fade left>
                <h2 className="testimonials_hedding">
                  <span className="text_yellow">OUR</span> TESTIMONIALS
                </h2>
                <div className="image_testimonials">
                  <img
                    className="testimonials_images"
                    src="assets/review.png"
                    alt=""
                  />
                  <div className="arrow_testimonials">
                    {testimonialIndex === testimonials.length - 3 ? (
                      <button className="btn2" onClick={scrollLeft}>
                        <BsArrowLeft />
                      </button>
                    ) : (
                      <button className="btn1" onClick={scrollLeft}>
                        <BsArrowLeftShort />
                      </button>
                    )}

                    {testimonialIndex !== testimonials.length - 3 ? (
                      <button className="btn2" onClick={scrollLeft}>
                        <BsArrowRight />
                      </button>
                    ) : (
                      <button className="btn1" onClick={scrollLeft}>
                        <BsArrowRightShort />
                      </button>
                    )}
                  </div>
                  <img
                    className="review_bg"
                    src="assets/review_bg.png"
                    alt=""
                  />
                  <div className="div_cercle">
                    <span>
                      <img src="assets/quwate.png" alt="" />
                    </span>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="col-md-6">
              <div className="view_all_btn">
                <Link to="/testimonials" className="view_all_testimonial">
                  View All
                </Link>
              </div>
              <Fade right>
                <div className="containsstest">
                  <a href="">- {testimonials[testimonialIndex].name}</a>
                  <p>{testimonials[testimonialIndex].feedback}</p>
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section className="article mt_100 container">
          <LightSpeed left>
            <div className="team_hedding ">
              <h2>
                <span className="text_yellow">latest</span> articles
              </h2>
              <p>
                Get the latest tips and tricks in technology and marketing to
                learn the best <br /> practices you can put into action. 
              </p>
            </div>
          </LightSpeed>

          {data ? (
            <Carousel responsive={responsive2}>
              {data.map((item, index) => (
                <div className="class_marin">
                  <Fade top>
                    <Link to={`blog/${item.url}`} className="article-blog ">
                      <img
                        src={`https://admin.crocentinternational.com/assets/upload/images/${item.blog_image}`}
                        alt=""
                      />
                      <div className="article_footer shadow-sm ">
                        <h5 className="date">{item.post_date}</h5>
                        <p className="headline">{item.blog_title}</p>
                        <Link to={`blogs/${item.url}`} className="read_more">
                          Read More
                        </Link>
                      </div>
                    </Link>
                  </Fade>
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="col-md-6">
              <Fade top>
                <div className="article-blog2">
                  <div className="shimmer-placeholder">
                    <Shimmer width={"100%"} height={400} />
                  </div>
                  <div className="article_footer shadow-sm">
                    <Shimmer width={"50%"} height={16} />
                    <br />
                    <Shimmer width={"100%"} height={16} />
                    <br />
                    <Shimmer width={"100%"} height={16} />
                    <br />
                    <a href="" className="read_more">
                      <Shimmer width={"20%"} height={16} />
                    </a>
                    <br />
                  </div>
                </div>
              </Fade>
            </div>
          )}
        </section>

        <section className="happyclient">
          <div className="row container">
            <div className=" col-3 happy_cld">
              <h5>
                {" "}
                <CountUp end={12000} />+
              </h5>

              <p>Projects Completed</p>
            </div>
            <div className=" col-3 happy_cld">
              <h5>
                <CountUp end={200} />+
              </h5>
              <p>Working Clients</p>
            </div>
            <div className=" col-3 happy_cld">
              <h5>
                <CountUp end={190} />+
              </h5>
              <p>Leads Generated</p>
            </div>
            <div className=" col-3 happy_cld">
              <h5>
                <CountUp end={50} />+
              </h5>
              <p>Apps Live</p>
            </div>
          </div>
        </section>

        <section className="get_in_touch_ci">
          <div className="bg_get_in_touch">
            <div></div>
          </div>
          <div className="row container">
            <div className="col-md-6">
              <Roll left>
                <div className="get_in_touch_images">
                  <img className="" src="assets/frame.png" alt="" />
                </div>
              </Roll>
            </div>

            <div className="col-md-6">
              <div className="get_in_from">
                <LightSpeed right>
                  <div className="touch_contain">
                    <h2 className="sub_heading2">
                      <span className="text_yellow">GET</span> IN TOUCH
                    </h2>
                    <p className="about-us-hedding">
                      Whether it is designing, development, multimedia or app
                      development, we are here for you. So, what are you waiting
                      for? Fill out the form and we’ll discuss your project.
                    </p>
                  </div>
                </LightSpeed>

                <LightSpeed right>
                  <Gettouch />
                </LightSpeed>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Home;
